<script type="text/ecmascript-6">

import Media from '@/mixins/media'
import Faq from '@/mixins/faq'
import Wording from '@/mixins/wording'
import Merchant from '@/mixins/merchant'
import Breadcrumbs from './BreadcrumbsV2.vue'

export default {
    props: {
        withBreadCrumbs: {

            type: Boolean,
            default: true
        }
    },
    components: {Breadcrumbs},
    mixins: [Media, Faq, Wording, Merchant],
    computed: {

        currentStep() {

            return this.$store.getters.currentStep
        },
        merchantLogo() {

            return this.getLocalImage(this.merchant.asset_logo_name)
        },
        platforName() {

            return this.getWording('PLATFORM_NAME')
        }
    },
}
</script>
<template>
    <header class="navbar navbar-expand-lg navbar-primary shadow-lg bg-white">
        <div class="row w-100">
            <div v-if="merchant" class="col-6 col-md-2 order-1 d-flex justify-content-center align-self-center">
                <router-link class="navbar-brand mx-md-0" :to="{name: 'home'}">
                    <img class="ms-4 img-fluid" :src="merchantLogo" :alt="platforName"/>
                </router-link>
            </div>
            <div class="col-12 col-md-8 row mt-5 order-3 order-md-2">
                <div v-if="withBreadCrumbs" class="offset-md-1 col-md-10">
                    <breadcrumbs :currentStep="currentStep" />
                </div>
            </div>
            <div class="col-6 col-md-2 order-2 d-flex justify-content-center flex-column">
                <a>
                    <img src="/assets/img/logo-greendid.webp" class="img-fluid">
                </a>
                <a :href="getFAQURL()" target="_blank" style="text-decoration: none;" class="text-primary">
                    <i class="bi bi-question-circle mt-1 text-success"></i> <u class="small">Centre d'aide</u>
                </a>
            </div>
        </div>
    </header>
</template>
